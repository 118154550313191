.button-options {
  font-family: RalewayBold;
  font-size: 2vw;
  padding: 10px;
  margin: 20px;
  color: white;
  text-align: right;
  background: none;
  border: none;
}

.button-options:hover {
  color: #aca6d7;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}
