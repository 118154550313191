@keyframes float {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(
      10px,
      -10px
    ); /* Hello your kissiness, adjust these values to make the stars move more or less */
  }
  100% {
    transform: translate(0px, 0px);
  }
}
