/* ProjectCarousel.css */

.project-carousel .carousel {
  position: relative;
}

.project-carousel .carousel-control-prev,
.project-carousel .carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.project-carousel .carousel-control-prev-icon,
.project-carousel .carousel-control-next-icon {
  width: 2rem;
  height: 2rem;
}

.project-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-carousel {
  padding-bottom: 20px;
}
