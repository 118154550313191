@font-face {
  font-family: RalewayRegular;
  src: url(fonts/Raleway-Regular.ttf);
}

@font-face {
  font-family: RalewayBlack;
  src: url(fonts/Raleway-Black.ttf);
}

@font-face {
  font-family: RalewayExtraBold;
  src: url(fonts/Raleway-ExtraBold.ttf);
}

@font-face {
  font-family: RalewayMedium;
  src: url(fonts/Raleway-Medium.ttf);
}

@font-face {
  font-family: RalewayThin;
  src: url(fonts/Raleway-Thin.ttf);
}

@font-face {
  font-family: RalewayBold;
  src: url(fonts/Raleway-Bold.ttf);
}

.App {
  background: linear-gradient(to bottom, #a86fc4, rgb(74, 32, 133));
  font-family: RalewayRegular;
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

html {
  background-color: rgb(74, 32, 133);
}
