.textContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align text to the left */
}

.mainText {
  font-family: "RalewayExtraBold", sans-serif;
  margin: 1vw;
  color: white;
}

.subText {
  font-size: 1vw;
  color: white;
}

.floatingText {
  animation: floatAnimation 5s ease-in-out infinite;
}

@keyframes floatAnimation {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

button:hover,
a:hover,
a:-webkit-any-link {
  cursor: url("../../images/cursor-hover.png"), auto !important;
}

body {
  cursor: url("../../images/cursor-pointer.png"), auto;
}

.main-container {
  width: 80%; /* Set the width to 90% of the viewport width */
  margin: 0 auto;
  margin-bottom: 10%;
  margin-top: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allow items to wrap to new lines if necessary */
}

.text-container {
  flex: 1; /* Take up as much space as available */
  text-align: center; /* Center-align text */
  padding: 2px; /* Add some padding */
}

.image-container {
  flex: 1; /* Take up as much space as available */
  text-align: left; /* Center-align image */
  padding: 2px; /* Add some padding */
}

.image-container img {
  max-width: 80%; /* Make sure image doesn't exceed container width */
  height: auto; /* Maintain aspect ratio */
}

.mainText {
  font-size: 5vw; /* Set font size as 3% of viewport width */
}

.subText {
  font-size: 1.5vw; /* Set font size as 2% of viewport width */
}

@media screen and (max-width: 10vw) {
  .main-container {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }
}

